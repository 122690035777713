// Libs.
import React from 'react';
// Deps.
import SEO from '../../components/layout/seo';
import Layout from '../../components/layout/layout';
import CheckoutComponent from '../../components/checkout/checkout-component';
// Hooks.
import useCheckoutAccess from '../../hooks/useCheckoutAccess';

const FMKCheckoutPage = () => {
  // Check if the user is ready to be able to see the checkout or not (logged in, or selected guest checkout).
  if (!useCheckoutAccess('fmk')) {
    return null;
  }

  return (
    <Layout>
      <SEO title="Festive Meal Kits Checkout" />
      <CheckoutComponent cartType="fmk" />
    </Layout>
  );
};

export default FMKCheckoutPage;
